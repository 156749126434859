.label {
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    color: #597C89;
}

input[type=text] {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7F99A4;
    border-radius: 10px;
    opacity: 1;
    height: 40px;
    margin-top: 10px;
    padding: 20px;
    font-weight: 300;
    width: 100%;
}

input[type=text]:focus {
    outline: none;
}
