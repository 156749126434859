.label {
    font-size: 1rem;
    font-weight: 500;
    color: #597C89;
}

input[type=text] {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7F99A4;
    border-radius: 10px;
    opacity: 1;
    height: 40px;
    padding: 20px;
    font-weight: 300;
}

.input-field {
    width: 300px;
    margin-bottom: 20px;
}

input[type=text]:focus {
    outline: none;
}

.errMsg {
    color: #FF0000;
    font-size: 0.85rem;
}

.left {
    float: left;
}

.right {
    float: right;
}

.dataInputBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 50%;
    justify-content: space-between;
    min-height: 400px;
}

.dataDisplayBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 50%;
    margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
    .input-field {
        max-width: 100%;
    }

    .left {
        width: 100%;
    }

    .right {
        width: 100%;
    }

    .dataDisplayBox {
        align-items: flex-start;
    }

    .dataInputBox {
        align-items: flex-start;
    }
}
