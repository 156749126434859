.left {
    float: left;
}

.right {
    float: right;
}

.step4-remark{
    width: 45%;
}

@media screen and (max-width: 768px) {
    .left {
        width: 100%;
    }

    .right {
        width: 100%;
    }
}

.sep-0 {
    height: 0px;
    clear: both;
    padding: 0;
}

.info-field {
    display: flex;
    flex-direction: column;
    min-width: 45%;
    margin-top: 20px;
}

.label {
    text-align: left;
    font-size: 1.1rem;
    font-weight: 500;
    color: #597C89;
}

.text {
    font-weight: 300;
    margin-top: 10px;
}

a {
    color: #00B289;
}

a:hover {
    color: #00B289;
}

