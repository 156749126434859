.carousel-indicators {
    margin-bottom: 10px;
}

.carousel-indicators li {
    width: 12px !important;
    height: 12px !important;
    border-radius: 50%;
    margin-right: 20px !important;
    background-color: #707070 !important;
    opacity: 1 !important;
    box-shadow: 2px 2px 5px;
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.carousel-indicators .active {
    background-color: #FFFFFF !important;
}
