.container {
    display: flex;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: 10px;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-radio-button {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    height: 25px !important;
    width: 25px !important;
    background-color: #eee;
    border-radius: 50%;
}

.custom-checkbox {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    height: 25px !important;
    width: 25px !important;
    background-color: #eee;
}

.container:hover input ~ .checkmark {
    background-color: #ccc;
}

.container input:checked ~ .checkmark {
    background-color: #00B289;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmark:after {
    display: block;
}

.container .custom-radio-button:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.container .custom-checkbox:after {
    left: 10px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.selection {
    color: #597C89;
    font-size: 1rem;
}