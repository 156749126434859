.left {
    float: left;
}

.right {
    float: right;
}

@media screen and (max-width: 768px) {
    .left {
        width: 100%;
    }

    .right {
        width: 100%;
    }
}

.sep-0 {
    height: 0px;
    clear: both;
    padding: 0;
}

.input-field {
    display: flex;
    flex-direction: column;
    min-width: 45%;
    margin-top: 20px;
}

.label {
    text-align: left;
    font-size: 1.1rem;
    font-weight: 500;
    color: #597C89;
}

label {
    cursor: pointer;
}

input[type=text] {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7F99A4;
    border-radius: 10px;
    opacity: 1;
    height: 40px;
    margin-top: 10px;
    padding: 20px;
    font-weight: 300;
}

textarea {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7F99A4;
    border-radius: 10px;
    opacity: 1;
    height: 80px;
    margin-top: 10px;
    padding: 20px;
    font-weight: 300;
    resize: none;
}

textarea:focus {
    outline: none;
}

.remark {
    font-size: 0.9rem;
    font-weight: 500;
    color: #597C89;
}

.star {
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    color: #FF0000;
}

input[type=text]:focus {
    outline: none;
}
